import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItem(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/settings/settings-get`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/settings/settings-save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        sendEmail(ctx, {sendType}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/settings/send-service-agreement-email?send_type=${sendType}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
